import React from 'react';
import Image from 'next/image';
import { Element as ScrollElement } from 'react-scroll';
import styled from 'styled-components';
import { H4, P2, H2, H5, Caption } from '../../../Typography';

const Mission = styled.div`
  display: flex;
  margin-bottom: 216px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const MissionStatementWrapper = styled.div`
  width: 50%;
  position: relative;
  background-color: ${({ theme }) => theme.colors.primary};
  padding: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 0px 100px 0px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    margin-bottom: 32px;
    padding: 64px 16px;
    border-radius: unset;
  }
`;

const MissionStatement = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: 0;
  position: relative;
  & > *:not(:last-child) {
    margin-bottom: 24px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin-left: unset;
    text-align: center;
  }
`;

const MissionStatementHeading = styled(H4)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const MissionStatementBody = styled(P2)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const MissionStatsContainer = styled.div`
  width: 50%;
  padding: 32px 64px;

  max-width: 603px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    max-width: none;
    width: 100%;
    padding: 32px;
    text-align: center;
  }
`;

const MissionStatsRow = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    flex-direction: column;
  }
`;

const MissionStatsColumn = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;

  & > *:not(:last-child) {
    margin-bottom: 24px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }

  &:last-child {
    width: 40%;

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
      display: none;
    }
  }
`;

const MissionStat = styled.div``;

const GreatPlaceToWorkImageDesktopWrapper = styled.div`
  align-self: center;
  justify-self: end;
  width: 120px;
  height: auto;
  margin-top: 46px;
  margin-left: 70px;
`;

const MissionStatHeading = styled(H2)`
  color: ${({ theme }) => theme.colors.primary};
`;

const CompanyMission: React.FC = () => (
  <Mission id="mission">
    <ScrollElement name="mission" />
    <MissionStatementWrapper>
      <MissionStatement>
        <MissionStatementHeading>OUR MISSION</MissionStatementHeading>
        <MissionStatementBody>
          Makeship aspires to scale custom product development and distribution for emerging content creators. We allow
          them to deepen their emotional connection to their fans, by bringing a physical manifestation of their digital
          content to life.
        </MissionStatementBody>
        <MissionStatementBody>
          Ultimately, we believe in a future where anyone can become a content creator, and that their individual
          creativity should be celebrated and given the chance to scale.
        </MissionStatementBody>
      </MissionStatement>
    </MissionStatementWrapper>
    <MissionStatsContainer>
      <H5>We&apos;re a young company, but growing fast!</H5>
      <MissionStatsRow>
        <MissionStatsColumn>
          <MissionStat>
            <MissionStatHeading>1,800+</MissionStatHeading>
            <Caption>Campaigns</Caption>
          </MissionStat>
          <MissionStat>
            <MissionStatHeading>$8.7M+</MissionStatHeading>
            <Caption>Paid to Creators</Caption>
          </MissionStat>
          <MissionStat>
            <MissionStatHeading>200%</MissionStatHeading>
            <Caption>Average Annual Sales Growth</Caption>
          </MissionStat>
        </MissionStatsColumn>
        <MissionStatsColumn>
          <GreatPlaceToWorkImageDesktopWrapper>
            <Image src="/assets/careers/careers-great-place-to-work.png" width={240} height={407} />
          </GreatPlaceToWorkImageDesktopWrapper>
        </MissionStatsColumn>
      </MissionStatsRow>
    </MissionStatsContainer>
  </Mission>
);

export default CompanyMission;
