import React from 'react';
import styled from 'styled-components';
import { H4, S1, Caption } from '../../../Typography';
import Container from '../../../Container';

const PerksAndBenefitsHeader = styled(H4)`
  color: ${({ theme }) => theme.colors.primary};
  margin-bottom: 16px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    text-align: left;
  }
`;

const Section = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 72px auto 0px auto;
  }
`;

const PerksSection = styled(Section)`
  display: flex;
  flex-direction: row;
  padding: 0 64px;
  align-items: flex-start;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 0;
    margin-left: 16px;
    margin-right: 16px;
    flex-direction: column;
  }
`;

const PerksImage = styled.img`
  max-width: 35%;
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const PerksImageMobile = styled.img`
  max-width: 100%;
  margin-bottom: 32.5px;
  padding-left: 16px;
  padding-right: 16px;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

const PerksWrapper = styled.div`
  margin-left: 64px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    text-align: center;
    margin: 0;
    margin-left: 12px;
  }
`;

const Perks = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: auto 16px auto 32px;
    flex-direction: column;
  }
`;

const PerkWrapper = styled.div`
  flex-basis: 40%;
  flex-grow: 1;
  margin: 24px 0 24px 20px;

  &:nth-child(2n) {
    margin-left: 48px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    text-align: left;
    margin: 24px 0 0;

    &:nth-child(2n) {
      margin-left: 0;
    }
  }
`;

const Perk = styled(S1)`
  &:before {
    content: '\\2022';
    color: ${({ theme }) => theme.colors.primary};
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
    font-size: 20px;
  }
`;

const CompanyPerks: React.FC = () => (
  <PerksSection>
    <PerksImage src="/assets/careers/careers-perks.png" />
    <PerksImageMobile src="/assets/careers/careers-perks-top.png" />
    <PerksWrapper>
      <PerksAndBenefitsHeader>PERKS &amp; BENEFITS</PerksAndBenefitsHeader>
      <Perks>
        <PerkWrapper>
          <Perk>Keep Healthy</Perk>
          <Caption>Stellar health and dental benefits for you and your dependents.</Caption>
        </PerkWrapper>
        <PerkWrapper>
          <Perk>Relax and Unplug</Perk>
          <Caption>Get paid time off every calendar year +official holidays off.</Caption>
        </PerkWrapper>
        <PerkWrapper>
          <Perk>Top Notch Equipment</Perk>
          <Caption>We provide you with the tools and equipment you need to succeed.</Caption>
        </PerkWrapper>
        <PerkWrapper>
          <Perk>Team Activities</Perk>
          <Caption>Company wide and team events and challenges to stay connected.</Caption>
        </PerkWrapper>
        <PerkWrapper>
          <Perk>Work from (almost) Anywhere</Perk>
          <Caption>Full-time remote, with coworking spaces available in our key cities.</Caption>
        </PerkWrapper>
        <PerkWrapper>
          <Perk>Flexible Working</Perk>
          <Caption>We believe in quality over quantity.</Caption>
        </PerkWrapper>
      </Perks>
    </PerksWrapper>
  </PerksSection>
);

export default CompanyPerks;
