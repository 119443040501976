import React from 'react';
import styled from 'styled-components';

import { Button } from '@makeship/core';
import posthog from 'posthog-js';
import { H3, H5, S1, P2 } from '../../../Typography';
import { BambooHRJobOpenings } from '../../../../api/bamboohr';

const JobBoardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 128px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 0 22px;
  }
`;

const JobBoardTitle = styled(H3)`
  margin-bottom: 24px;
`;

const JobList = styled.div`
  width: 693px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
  }
`;

const JobContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  align-items: flex-start;
`;

const DepartmentSpacer = styled.div`
  margin-bottom: 64px;
`;

const CategoryTitle = styled(H5)`
  margin-bottom: 16px;
`;

const JobTitleWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    width: 100%;
    text-align: left;
  }
`;

const JobTitle = styled(H5)`
  color: ${({ theme }) => theme.colors.primary};
  &:hover {
    color: ${({ theme }) => theme.colors.primaryDark};
    cursor: pointer;
    text-decoration: underline;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: none;
  }
`;

const JobTitleMobile = styled(S1)`
  color: ${({ theme }) => theme.colors.primary};
  display: none;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    display: block;
    &:hover {
      color: ${({ theme }) => theme.colors.primaryDark};
      cursor: pointer;
    }
  }
`;

const JobDetails = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    max-width: 216px;
  }
`;

const JobLocation = styled(P2)`
  opacity: 0.6;
`;

const ApplyJobButton = styled(Button.Primary)`
  border-radius: 30px;
`;

type JobBoardProps = {
  openJobs: BambooHRJobOpenings[];
};

const JobBoard: React.FC<JobBoardProps> = ({ openJobs }: JobBoardProps) => {
  const getDepartmentName = (departmentJobs: BambooHRJobOpenings): string =>
    departmentJobs.department.label ? departmentJobs.department.label : 'Other';

  const getJobsFromDepartment = (departmentJobs: BambooHRJobOpenings[], department: string): BambooHRJobOpenings[] =>
    departmentJobs.filter((job) => getDepartmentName(job) === department);

  const getAllDepartmentNamesFromJSON = (sortedJobs: BambooHRJobOpenings[]): string[] => {
    const departmentNames: string[] = [];
    for (let i = 0; i < sortedJobs.length; i += 1) {
      if (!departmentNames.includes(getDepartmentName(sortedJobs[i]))) {
        departmentNames.push(getDepartmentName(sortedJobs[i]));
      }
    }
    return departmentNames;
  };

  const handleJobClick = (job: BambooHRJobOpenings) => {
    posthog.capture('careers_jobPosting_click', {
      job_id: job.id,
      job_title: job.title.label,
      job_department: job.department.label,
      job_location: 'Remote Canada',
      job_url: job.postingUrl,
    });
  };

  const createJobDetails = (currJob: BambooHRJobOpenings) => (
    <>
      <JobDetails>
        <a target="_blank" href={currJob.postingUrl} rel="noopener noreferrer" onClick={() => handleJobClick(currJob)}>
          <JobTitle>{currJob.title.label}</JobTitle>
        </a>
        <a target="_blank" href={currJob.postingUrl} rel="noopener noreferrer" onClick={() => handleJobClick(currJob)}>
          <JobTitleMobile>{currJob.title.label}</JobTitleMobile>
        </a>
        <JobLocation>Remote Canada</JobLocation>
      </JobDetails>
      <a target="_blank" href={currJob.postingUrl} rel="noopener noreferrer" onClick={() => handleJobClick(currJob)}>
        <ApplyJobButton size={Button.Size.small}>Apply</ApplyJobButton>
      </a>
    </>
  );

  const renderJobs = () => {
    const renderedJobs = [];

    const departmentNames: string[] = getAllDepartmentNamesFromJSON(openJobs);

    // Get arrays of job arrays by department
    const sortedDepartments: BambooHRJobOpenings[][] = [];
    for (let i = 0; i < departmentNames.length; i += 1) {
      sortedDepartments.push(getJobsFromDepartment(openJobs, departmentNames[i]));
    }

    // Go through each department
    for (let i = 0; i < sortedDepartments.length; i += 1) {
      const departmentJobs = sortedDepartments[i];

      // Render the title if it is a new department
      if (departmentJobs.length > 0) {
        renderedJobs.push(<CategoryTitle key={i}>{getDepartmentName(departmentJobs[0])}</CategoryTitle>);
      }
      // Get job + details in this category
      for (let j = 0; j < departmentJobs.length; j += 1) {
        const currJob = departmentJobs[j];
        const currJobDetails = createJobDetails(currJob);
        renderedJobs.push(<JobContainer key={currJob.id}>{currJobDetails}</JobContainer>);
      }
      renderedJobs.push(<DepartmentSpacer />);
    }
    return renderedJobs;
  };

  return (
    <JobBoardWrapper>
      {openJobs.length > 0 && (
        <JobTitleWrapper>
          <JobBoardTitle>Job Openings</JobBoardTitle>
        </JobTitleWrapper>
      )}
      <JobList>{renderJobs()}</JobList>
    </JobBoardWrapper>
  );
};

export default JobBoard;
