import React from 'react';
import Image from 'next/image';
import { Button } from '@makeship/core';
import styled from 'styled-components';
import posthog from 'posthog-js';
import Container from '../../../Container';
import { H5 } from '../../../Typography';
import config from '../../../../../config.json';

const ValuesHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
`;

const ValuesHeader = styled(Image)`
  object-fit: cover;
`;

const ValuesImage = styled.img`
  width: 75%;
  margin-bottom: 64px;
  max-width: 1080px;
  display: block;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: none;
  }
`;

const ValuesImageMobile = styled.img`
  max-width: 100%;
  margin-bottom: 36px;
  padding-left: 16px;
  padding-right: 16px;
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    display: block;
  }
`;

const RoundedPrimaryButton = styled(Button.Primary)`
  border-radius: 30px;
  margin-bottom: 64px;
`;

const Section = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 120px auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    margin: 72px auto 0px auto;
  }
`;

const ValuesContent = styled(H5)`
  text-align: center;
  margin: 32px 16px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
  }
`;

const ValuesSection = styled(Section)`
  width: 100%;
  max-width: unset;
  background: linear-gradient(180deg, rgba(232, 224, 253, 0) 47.37%, #e8e0fd 100%);
`;

const CompanyValues: React.FC = () => (
  <ValuesSection>
    <ValuesImage src="/assets/careers/careers-retreat.png" />
    <ValuesImageMobile src="/assets/careers/careers-retreat-mobile.png" />
    <ValuesHeaderWrapper>
      <ValuesHeader layout="fixed" width={440} height={44} src="/assets/careers/values-header.gif" />
    </ValuesHeaderWrapper>
    <ValuesContent>Our values are fueled by our relentlessly consistent, core principles.</ValuesContent>
    <a
      target="_blank"
      href={`${config.routes.aboutUs}`}
      rel="noopener noreferrer"
      onClick={() => posthog.capture('careers_aboutUsButton_click')}
    >
      <RoundedPrimaryButton>Learn More</RoundedPrimaryButton>
    </a>
  </ValuesSection>
);

export default CompanyValues;
